<script lang="ts">
  // Components
  import { Grid } from "$lib/components/organisms/Layout";
</script>

<section class="partners">
  <Grid>
    <div class="partners-container">
      <div class="partners-list">
        <slot />
      </div>
    </div>
  </Grid>
</section>

<style type="text/scss" lang="scss" scoped>
  .partners {
    @apply mb-8;
    @apply md:mb-16;
  }

  .partners-container {
    @apply col-span-full;
    @apply overflow-auto;
    @apply flex justify-center;
    margin-left: calc(var(--page-margin) * -1);
    margin-right: calc(var(--page-margin) * -1);
    padding-left: var(--page-margin);
    padding-right: var(--page-margin);

    &::-webkit-scrollbar {
      @apply hidden;
    }
  }

  .partners-list {
    @apply inline-flex items-center;
    @apply -mx-5;
    @apply md:-mx-6;
  }

  :global(.partners-list > *) {
    @apply flex-1;
    @apply object-contain;
    @apply h-10 w-auto;
    @apply mx-5;
    @apply md:h-12;
    @apply md:mx-6;
  }
</style>
